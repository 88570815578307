import React, { useMemo } from 'react'
import qs from 'querystringify'
import Seo from '../../components/Seo'
import Layout from '../../components/layout'
import StructuredData from '../../components/structured-data'
import SearchForm from '../../components/search/search-form'
import SubscribeWidget from '../../components/subscribe-widget/subscribe-widget'
import CategoryWidget from '../../components/category-widget/category-widget'
import QuestionsWidget from '../../components/questions-widget/questions-widget'
import GCSESearch from '../../components/search/gcse-search'

function Search({ location }) {
  const { search } = location;

  const searchQuery = useMemo(() => {
    const { q: qsSearch } = qs.parse(search);
    return qsSearch || '';
  }, [search]);

  const post = useMemo(() => {
    return { seo: {
      title: searchQuery ? `You searched for ${searchQuery}` : 'Search',
    } };
  }, [searchQuery]);

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="webPage" />
      <SearchForm initialValue={searchQuery} kb />
      <div className="kb-searchresults-only container grid my-5 lg:grid-cols-12 lg:row-gap-0 lg:col-gap-20">
        <div className="lg:col-span-7 xl:col-span-8 pb-2">
          <h1
            className="pb-2 mb-8 border-b knowledgeBaseTextColor"
            style={{ borderBottomColor: '#d9d9d9' }}
          >
            {`Search Results for: ${searchQuery}`}
          </h1>
          <GCSESearch value={searchQuery} />
        </div>
        <div className="lg:col-span-5 xl:col-span-4 my-4 ml-0 lg:ml-10">
          <div className="p-4" style={{ backgroundColor: '#f4f4f4' }}>
            <SubscribeWidget isKB />
            <CategoryWidget className="mt-10" knowledgeBase />
            <QuestionsWidget className="my-10" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Search
